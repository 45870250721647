
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    color: { default: 'red' },
    text: { default: '' },
    progress: { default: 0 },
    max: { default: 100 },
  },
  computed: {
    percentage() {
      return ((this.progress / this.max) * 100).toFixed(1);
    },
  },
});
