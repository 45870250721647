
import { defineComponent } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import time from '@/utils/time';

export default defineComponent({
  props: {
    color: { default: 'red' },
    text: { default: '' },
    count: { default: 0 },
    icon: { default: 'fas fa-building' },
    modelValue: { type: Array, default: () => [] },
  },
  data() {
    return {
      colors: {
        red: {
          stroke: '#F64E60',
          bgColor: '#FFE2E5',
        },
        blue: {
          stroke: '#3699FF',
          bgColor: '#E1F0FF',
        },
        green: {
          stroke: '#1BC5BD',
          bgColor: '#AFFFFB',
        },
        purple: {
          stroke: '#990ABD',
          bgColor: '#F1BBFF',
        },
      },
    };
  },
  emits: ['update:modelValue'],
  computed: {
    graphData: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    graphLabels() {
      return Object.keys(this.graphData).map((item) => {
        return time.dateFormat(item, 'aus');
      });
    },
    graphValues() {
      return Object.values(this.graphData);
    },
    options() {
      return {
        series: [
          {
            name: this.text,
            data: this.graphValues,
          },
        ],
        chart: {
          fontFamily: 'inherit',
          type: 'area',
          height: 150,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'solid',
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 4,
          colors: [this.colors[this.color].stroke],
        },
        xaxis: {
          categories: this.graphLabels,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            style: {
              colors: '#B5B5C3',
              fontSize: '12px',
            },
          },
          crosshairs: {
            show: false,
            position: 'front',
            stroke: {
              color: '#E4E6EF',
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: true,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          min: 0,
          max: 60,
          labels: {
            show: false,
            style: {
              colors: '#B5B5C3',
              fontSize: '12px',
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: [this.colors[this.color].bgColor],
        markers: {
          colors: [this.colors[this.color].bgColor],
          strokeColor: [this.colors[this.color].stroke],
          strokeWidth: 3,
        },
      };
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
});
