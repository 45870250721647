
import { defineComponent } from 'vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import WidgetCountSummary from '@/views/new-design/pages/Dashboard/Widgets/WidgetCountSummary.vue';
import Progress from '@/views/new-design/pages/Dashboard/Widgets/Progress.vue';
import AreaGraph from '@/views/new-design/pages/Dashboard/Widgets/AreaGraph.vue';
import { Actions, Getters, Mutations } from '@/store/enums/DashboardEnums';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import timeUtil from '@/utils/time';
import moment from 'moment';

export default defineComponent({
  components: { WidgetCountSummary, Progress, AreaGraph },
  data: () => {
    return {
      shortcuts: [
        {
          text: 'Last 7 days',
          value: () => {
            return timeUtil.dateRange(7);
          },
        },
        {
          text: 'Last 14 days',
          value: () => {
            return timeUtil.dateRange(14);
          },
        },
        {
          text: 'This month',
          value: timeUtil.dateRange(30),
        },
        {
          text: 'Last month',
          value: () => {
            return timeUtil.dateRange(30);
          },
        },
      ],
      selectedDates: timeUtil.dateRange(7) as any,
      debounceGetStatistics: Function(),
    };
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      statistics: Getters.GET_STATISTICS,
    }),
    dateRange() {
      if (this.selectedDates.length) {
        return [
          timeUtil.dateFormat(this.selectedDates[0], 'default'),
          timeUtil.dateFormat(this.selectedDates[1], 'default'),
        ];
      }

      return [
        timeUtil.dateFormat(moment().format(), 'default'),
        timeUtil.dateFormat(moment().format(), 'default'),
      ];
    },
    agentGoal() {
      return process.env.VUE_APP_AGENT_GOAL;
    },
    getDaysBetweenFilteredDays() {
      let now = moment(this.dateRange[0]).clone();
      let end = moment(this.dateRange[1]);

      let dateValues = {} as any;

      while (now.isSameOrBefore(end)) {
        let time = now.format('YYYY-MM-DD');
        dateValues[time] = 0;

        now.add(1, 'days');
      }

      return dateValues;
    },
  },
  watch: {
    selectedDates: {
      handler(value) {
        if (value.length) {
          this.debounceGetStatistics();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchStatistics: Actions.FETCH_STATISTICS,
    }),
    async getStatistics() {
      await this.fetchStatistics({
        from: this.dateRange[0],
        to: this.dateRange[1],
      }).catch(() => {
        console.log();
      });
    },
    formatGraphValue(value) {
      if (!value) {
        return this.getDaysBetweenFilteredDays;
      }

      return value;
    },
  },
  created() {
    this.debounceGetStatistics = _.debounce(() => {
      this.getStatistics();
    }, 1000);
  },
  async mounted() {
    this.getStatistics();
  },
});
