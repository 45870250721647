import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e2e7396"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card card-xl-stretch mb-xl-8 graph" }
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = {
  class: "d-flex flex-stack card-p flex-grow-1",
  style: {"height":"93px !important"}
}
const _hoisted_4 = { class: "symbol symbol-50px me-2" }
const _hoisted_5 = { class: "svg-icon svg-icon-2x svg-icon-info" }
const _hoisted_6 = { class: "d-flex flex-column text-end" }
const _hoisted_7 = { class: "text-dark fw-bolder fs-2" }
const _hoisted_8 = { class: "text-muted fw-bold mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("span", {
            class: "symbol-label bg-light-info",
            style: _normalizeStyle(`border-radius: 50px;background:${_ctx.colors[_ctx.color].stroke}!important`)
          }, [
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("span", {
                class: _normalizeClass(`${_ctx.icon} fa-2x`),
                style: _normalizeStyle(`color:${_ctx.colors[_ctx.color].bgColor}!important`)
              }, null, 6)
            ])
          ], 4)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.count), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.text), 1)
        ])
      ]),
      _createVNode(_component_apexchart, {
        width: "100%",
        height: "150px",
        type: "area",
        options: _ctx.options,
        series: _ctx.options.series
      }, null, 8, ["options", "series"])
    ])
  ]))
}